@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* @primary-color: #333333;
@secondary-color: #2e2e2e;
@text-color: #000000;
@link-color: #3f51b5;
@white-color: #ffffff;
@dark-color: #333333;
@gray-1:#333333;
@gray-2:#2e2e2e;
@gray-3:#262626;
@gray-4:#efefef;
@gray-5:#D9D9D9;
@gray-6:#f2f2f2;
@error-color:#C90000;

@body-text:'Satoshi-Regular';
@heading-text:'Satoshi-Bold';
@semy-heading-text:'Satoshi-Medium';
@link-text:'Satoshi-Regular';
@button-text:'Averta Std PE';


@input-background:#efefef; */

  

html,
body,
#root {
  width: 100%;
  height: 100vh;
  /* overflow: hidden !important; */
}

/* Webkit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scroll thumb color */
  border-radius: 5px; /* Rounded edges */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background of the scrollbar track */

}

/* Change on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/*to hide scroll bar for home page */
.custom-scroll::-webkit-scrollbar {
  display: none;
}

@layer base {
  :root {
    --primary-color: #333333;
    --secondary-color: #2e2e2e;
    --white: #ffffff;
    --text-color: #262626;
    --link-color: #3f51b5;
    --white-color: #ffffff;
    --dark-color: #000000;
    --gray-1: #5f6368e5;
    --gray-2: #2e2e2e;
    --gray-3: #f0f0f0;
    --gray-4: #efefef;
    --gray-5: #d9d9d9;
    --gray-6: #f7f7f7;
    --input-background: #f7f7f7;
    --header-background: #ffffff;
    --input-text: #262626;
    --input-placeholder: #7e7e7e;
    --input-label: #262626;
    --error-color: #c90000;
  }
}

.apple-button {
  width: 100%;
  background-color: #efefef;
  border: 1px solid #efefef !important;
  color: #747474 !important;
  svg {
    fill: #747474;
  }
}
.apple-button:hover {
  filter: grayscale(0);
  background-color: #000 !important;
  color: #ffffff !important;
  border: 1px solid #dedede !important;
  svg {
    fill: #fff;
  }
}

.google-button {
  width: 100%;
  background-color: #efefef;
  border: 1px solid #efefef !important;
  filter: grayscale(1);
  color: #747474 !important;
}
.google-button:hover {
  color: #000 !important;
  filter: grayscale(0);
  background-color: #fff !important;
  border: 1px solid #dedede !important;
}

[type="text"]:focus,
input:where(:not([type])):focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
  border-color: transparent !important;
  outline: none !important;
}

#Carousel-Item-slider,
#Carousel-Item-slider .slick-slider,
#Carousel-Item-slider .slick-slider .slick-list,
#Carousel-Item-slider .slick-slider .slick-list .slick-track,
#Carousel-Item-slider .slick-slider .slick-list .slick-track .slick-slide,
#Carousel-Item-slider
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div {
  height: 100%;
}

#Carousel-Item-slider .slick-arrow {
  width: 40px !important;
  height: 40px !important;
  background: var(--primary-color) !important;
  color: var(--white) !important;
  border-radius: 100px !important;
}

#testimonial-slider .slick-dots.slick-dots-bottom {
  bottom: -40px !important;
}

#testimonial-slider .slick-dots li {
  width: 12px !important;
}
#testimonial-slider .slick-dots li button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 100px !important;
  background: #7e7e7e !important;
  border: 1px solid #000 !important;
}
#testimonial-slider .slick-dots li.slick-active {
  width: 12px !important;
}
#testimonial-slider .slick-dots li.slick-active button {
  width: 12px !important;
  height: 12px !important;
  background: #000000 !important;
  border: 1px solid #000 !important;
}

#reviews-slider .slick-slide {
  height: 100% !important;
}

/* slick-next */
/* slick-arrow slick-prev */

/*
.site-header{
    padding:10px;
    background-color: var('--white');
}

.header-search{
    border-radius: 30px;
    position: relative;
    border: 1px solid var('--gray-2');
    overflow: hidden;
    
}
.header-search svg{
    position: absolute;
    top: 10px;
    left: 10px;
}
.header-search input{
    border: 0px;
}

.auth{
    height: 100%;
    width: 100%;
    background-color: var('--white');
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
}

.auth .auth-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 20px;

}

.auth .auth-header .back-button{
    background-color: var('--gray-4');
    color: var('--gray-2');
    border: 0px;
}

.auth .auth-card{
    width: 100%;
    padding: 20px;
    max-width: 420px;
    margin: auto;
    text-align: center;
}

.auth .auth-card .logo{
    width:160px;
    margin-bottom: 20px;
}

.auth .auth-card .title{
    font-family: var('--heading-text');
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    margin-bottom:30px;
}

.auth .auth-card .social-login{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}

.auth .auth-card .social-login .ant-btn{
    background-color: var('--gray-4');
    color: var('--gray-3');
    border: 0;
    width: 48%;
    padding: 20px;
    font-family: var('--button-text');
    font-size: 15px;
    font-weight: 700;
    line-height: 22.5px;
    text-align: left;
}


.auth .auth-card .divider{
    font-family: var('--button-text');
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: left;
}

.auth .auth-card .signin-signup{
    background-color: var('--primary-color');
    color: var('--white-color');
    width: 100%;
    padding: 20px;
    font-family: var('--button-text');
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
}

.auth .auth-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.auth .auth-footer p{
    font-family: var('--body-text');
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.signin{
    height: 100%;
    width: 100%;
    background-color: var('--white-color');
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
}

.signin .signin-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.signin .signin-header .back-button{
    background-color: var('--gray-4');
    color: var('--gray-2');
    border: 0px;
}

.signin .signin-card{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    max-width: 420px;
    margin: auto;
    text-align: left;
}

.signin .signin-card .logo{
    width:160px;
    margin-bottom: 20px;
}

.signin .signin-card .title{
    font-family: var('--heading-text');
    font-size: 28px;
    line-height: 42px;
    text-align: left;
    margin-bottom:10px;
}

.signin .signin-card .title.center{
    text-align: center; 
}

.signin .signin-card .info{
    margin-bottom: 20px;
}

.signin .signin-card .social-login{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.signin .signin-card .ant-btn{
    background-color: var('--gray-4');
    color: var('--gray-3');
    border: 0;
    width: 48%;
    padding: 20px;
    font-family: var('--button-text');
    font-size: 15px;
    font-weight: 700;
    line-height: 22.5px;
    text-align: left;
}

.signin .signin-card .divider{
    font-family: var('--button-text');
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: left;
}

.signin .signin-card .signin-signup{
    background-color: var('--primary-color');
    color: var('--white-color');
    width: 100%;
    padding: 20px;
    font-family: var('--button-text');
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
}

.signin .signin-card .change_number{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    margin-bottom: 24px;
    font-family: var('--heading-text');
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
    color: var('--text-color');
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.signin .signin-card .resend-code{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:24px;       
}

.signin .signin-card .ant-btn{
    padding: 0px;
}

.signin .signin-card .ant-form{
    display: flex;
    flex-direction: column;
    gap: 2px;    
}

.signin .signin-card .ant-form-item{
    text-align: left;
}


.signin .signin-card .text-input{
    background: var('--input-background');
    border: 0;
    height: 54px;
}

.signin .signin-card .ant-select-selector{
    background: transparent;
}

.signin .signin-card .ant-otp{
    width:99%;
    height: 54px;
}

.signin .signin-card .ant-otp .ant-input{
    height: 100%;
    width: 100%;
}

.signin .signin-card .ant-otp .ant-form-item-explain-error{
    font-family: var('--semy-heading-text');
    color: var('--error-color');
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: left;
    line-height: 28px;
    font-size: 14px;
}


.signin .signin-card .sign-submit{
    background-color: var('--primary-color');
    color: var('--white-color');
    font-family: var('--button-text');
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    line-height: 21px;
    text-align: center;
    height:54px;
}

.signin .signin-card .forgot-pass{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 24px;
}

.signin .signin-card .forgot-pass a{
    display: inline-flex;
    font-family: var('--semy-heading-text');
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var('--text-color');
    text-align: right;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.signin .signin-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}

.signin .signin-footer p{
    font-family: var('--body-text');
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.site-intro {
    height: 100%;
    width: 100%;
    background-color: var('--gray-6');
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:flex-start;
    padding-left: 84px;
    padding-bottom: 84px;
}

.site-intro .intro-card .title{
    font-family: var('--heading-text');
    font-size: 32px;
    line-height: 42px;
    text-align: left;
    margin-bottom: 30px;
}

.site-intro .intro-card ul.list{
    list-style: none;
    margin: 0;
    padding: 0;
}

.site-intro .intro-card ul.list li{
    display:flex;
    gap:20px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;    
}

.site-intro .intro-card ul.list li div:first-child{
    width: 50px;
    height: 50px;
    background: var('--gray-5');
    border-radius: 10px;
}

.site-intro .intro-card ul.list li div:last-child h3{
    font-family: var('--heading-text');
    font-size: 20px;
    line-height: 32px;
    text-align: left;    
}

.site-intro .intro-card ul.list li div:last-child p{
    font-family: var('--semy-heading-text');
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

*/
